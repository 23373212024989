import { FC, ReactElement } from "react";
import { Link } from "react-router-dom";

export const ProjectCard: FC<{
  title: string;
  description: ReactElement;
  image: string;
  link: string;
}> = ({ title, description, image, link }) => {
  return (
    <Link
      to={link}
      key={title}
      className="w-full max-w-screen-2xl flex justify-center "
    >
      <div className="bg-light dark:bg-black w-full h-96 overflow-hidden border-4 border-primary group relative">
        <section className="absolute top-0 left-0 w-full group-hover:w-1/3 h-full z-10 duration-500 flex items-center justify-center text-white">
          <div className="absolute top-0 left-0 w-full h-full bg-primary opacity-0 group-hover:opacity-50 duration-500 z-20" />
          <h2 className="z-10 relative duration-300 group-hover:opacity-0">
            {title}
          </h2>
          <img
            src={image}
            alt={title}
            className="w-full h-full object-center absolute top-0 left-0 object-cover brightness-50"
          />
        </section>
        <section
          className="absolute -right-2/3 group-hover:right-0 duration-500
         float-right w-2/3 h-full p-4 sm:p-8"
        >
          <div className="w-max">
            <p
              className="
                text-lg
                xs:text-xl
                sm:text-3xl
                md:text-4xl
                lg:text-5xl
                xl:text-6xl
              "
              style={{
                fontFamily: "Helvetica-Neue-LT-Std-83-Heavy-Extended",
              }}
            >
              {title.toUpperCase().replaceAll(" ", "//")}
            </p>
            <div className="bg-gradient-to-r from-primaryDark to-primary h-1 w-full" />
          </div>

          <h5 className="py-2 sm:py-4">{description}</h5>
        </section>
      </div>
    </Link>
  );
};
