import { create } from "zustand";

type PageScrollStore = {
  scrollY: number;
  scrollYProgress: number;

  setScrollY: (position: number) => void;
  setScrollYProgress: (percentage: number) => void;
};

export const usePageScrollStore = create<PageScrollStore>()((set) => ({
  scrollY: 0,
  scrollYProgress: 0,

  setScrollY: (position) => set(() => ({ scrollY: position })),
  setScrollYProgress: (percentage) =>
    set(() => ({ scrollYProgress: percentage })),
}));
