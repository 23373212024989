import { FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { Layout } from "./Layout";
import { _404 } from "../pages/_404";
import { Home } from "../pages/Home";
import { About } from "../pages/About";
import { Projects } from "../pages/Projects";
import { ProjectPageWrapper } from "../pages/ProjectPageWrapper";
import { Tech } from "../pages/Tech";
import { Contact } from "../pages/Contact";

export const AnimatedRoutes: FC = () => {
  const location = useLocation();
  return (
    <AnimatePresence
      mode="wait"
      onExitComplete={() => {
        window.scrollTo({ top: 0 });
      }}
    >
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} key="index" />
          <Route path="about" element={<About />} key="about" />
          <Route path="projects" key="projects" element={<Projects />} />
          <Route
            path="projects/:project"
            element={<ProjectPageWrapper />}
            key="projectPage"
          />
          <Route path="tech" element={<Tech />} key="tech" />
          <Route path="contact" element={<Contact />} key="contact" />

          <Route path="*" element={<_404 />} key="404" />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
