import { FC, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

export const MenuEntry: FC<{
  link: string;
  listIndex: number;
  children: ReactNode;
}> = ({ link, listIndex, children }) => {
  const words = children ? children.toString().split(" ") : [];
  const animationController = useAnimation();

  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    const delays = [500, 1600, 1600];
    ["entry", "originFlip", "exit"].forEach((state, animationIndex) => {
      setTimeout(() => {
        try {
          animationController.start(state);
        } catch {}
      }, delays[animationIndex] + listIndex * 500);
    });

    setTimeout(() => {
      setIsTextVisible(true);
    }, 1600 + listIndex * 500);
  }, [animationController]);

  return (
    <div className="relative w-max">
      {["bg-primaryDark", "bg-primary", "bg-black dark:bg-light"].map(
        (color, index) => {
          const variants = {
            initial: {
              scaleX: 0,
              originX: 0,
            },
            entry: {
              scaleX: 1,
              transition: {
                delay: index * 0.1,
                duration: 0.5,
                ease: [0.9, 0, 0, 1],
              },
            },
            originFlip: {
              originX: 1,
              transition: {
                delay: 0,
                duration: 0,
              },
            },
            exit: {
              scaleX: 0,
              transition: {
                delay: 0.3 - index * 0.1,
                duration: 0.5,
                ease: [0.9, 0, 0, 1],
              },
            },
          };

          return (
            <motion.div
              key={index}
              className={`absolute w-full h-full ${color}`}
              initial="initial"
              animate={animationController}
              variants={variants}
            />
          );
        }
      )}
      <Link to={link} className={!isTextVisible ? "pointer-events-none" : ""}>
        <div
          className={`menuEntry hover:-ml-[0.25em] text-xl xs:text-3xl md:text-4xl group ${
            !isTextVisible && "opacity-0"
          }`}
          style={{
            transitionProperty: "margin-left",
            transitionDuration: "0.3s",
          }}
        >
          {words.map((word, index) => (
            <span key={index}>
              {word}
              {index !== words.length - 1 && (
                <span
                  className="menuEntrySeperator"
                  style={{
                    transitionProperty: "margin",
                    transitionDuration: "0.3s",
                  }}
                >
                  &#x2f;&#x2f;
                </span>
              )}
            </span>
          ))}

          <div className="bg-gradient-to-r from-primaryDark to-primary h-0.5 w-full opacity-0 duration-300 group-hover:opacity-100" />
        </div>
      </Link>
    </div>
  );
};
