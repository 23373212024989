import { FC, ReactNode, MouseEventHandler } from "react";

export const Button: FC<{
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
}> = ({ onClick, children }) => {
  return (
    <div className="relative group">
      <button
        onClick={onClick}
        className="w-full h-full bg-primary border-2 border-primary group-hover:bg-light group-hover:dark:bg-black duration-300 text-white group-hover:text-black group-hover:dark:text-white text-2xl p-4 relative z-10"
      >
        {children}
      </button>
      <div
        className="
        h-[calc(100%_+_10px)] -mt-[5px]
        w-[calc(100%_+_10px)] -ml-[5px]
        opacity-0 group-hover:opacity-100
        duration-300 bg-primary absolute top-0 left-0 blur-xl
        "
      />
    </div>
  );
};
