import { FC } from "react";
import { PageWrapper } from "../components/PageWrapper";
import { ProjectCard } from "../components/ProjectCard";
import { PageHeader } from "../components/PageHeader";

import ValorizeHero from "../images/projects/valorize/match.png";
import CSHero from "../images/projects/cs-invites/invite.png";

export const Projects: FC = () => {
  return (
    <PageWrapper>
      <div className="flex flex-col gap-16 items-center">
        <PageHeader>MY//PROJECTS</PageHeader>
        <section>
          <h5 className="text-center px-8 max-w-screen-2xl">
            Development and designing are some of my favorite things to do. I
            mainly develop fun, useful or interesting tools that I believe have
            a valid use case, either just for myself or for others. A good user
            experience and nice eye-candy visuals are also important to me, so I
            love to make my projects look unique and appealing.
            <br />
            <br />
            Over the years I have worked on countless smaller projects, most
            being either lost to time or not living up to my current quality
            standards. Below are some of my more recent projects, as well as a
            tiny amount of older ones that are worth mentioning.
          </h5>
        </section>
        <section className="max-w-screen-2xl w-full flex flex-col gap-4">
          <ProjectCard
            title="VALORIZE"
            description={
              <>
                VALORIZE is a VALORANT companion app using internal API data,
                allowing players to view hidden data such as MMR/Ranks, match
                history, inventories and more of both themselves and other
                players
              </>
            }
            image={ValorizeHero}
            link="valorize"
          />

          <ProjectCard
            title="CS Invite Bot"
            description={
              <>
                A CS2 (and CS:GO) lobby invitation spoofer tool and exploit,
                allowing sending notifications to any player in the game, even
                if they are not on your friends list, with any custom message
              </>
            }
            image={CSHero}
            link="cs-invites"
          />
        </section>

        <section className="w-full max-w-screen-2xl flex flex-col gap-4 mb-8">
          <div className="blackSelection">
            <h2 className="bg-primary text-white p-2 text-center duration-300 ">
              Older Projects
            </h2>
          </div>
          <h5>
            <div className="flex flex-col px-8 w-full gap-4">
              {[
                {
                  title: "Chinatsu.pink",
                  description: (
                    <>
                      A fully fleshed-out image-based social media with
                      accounts, followers, personal feed and more.
                      <br />
                      (Planning on rebuilding this project in the future)
                    </>
                  ),
                },
                {
                  title: "GroupDB.xyz",
                  description: (
                    <>
                      The first proper index of "Steam" community groups with
                      data acquired by web scraping.
                      <br />
                      300+ registered users, all from word-of-mouth from other
                      users.
                    </>
                  ),
                },
                {
                  title: "WHI Bot",
                  description: (
                    <>
                      A fully automatic social media bot for the, now
                      discountined, social platform "WeHeartIt", mainly for
                      posting images and managing collections on an hourly
                      basis. Built with headless browser automation (Puppeteer).
                    </>
                  ),
                },
                {
                  title: "Chat Bots",
                  description: (
                    <>
                      Countless chat bots for many different platforms (both
                      officially supported and not), including Discord, Steam
                      and Twitch.
                    </>
                  ),
                },
                {
                  title: "APIs",
                  description: (
                    <>
                      Various small APIs, mainly for supplying other projects.
                    </>
                  ),
                },
              ].map((project) => (
                <div className="flex flex-col" key={project.title}>
                  <p className="font-bold text-primary">&gt; {project.title}</p>
                  <p>{project.description}</p>
                </div>
              ))}
            </div>
          </h5>
        </section>
      </div>
    </PageWrapper>
  );
};
