import { FC, ReactNode } from "react";

export const PageHeader: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div>
      <h1 className="text-center">{children}</h1>
      <div className="bg-gradient-to-r from-primaryDark to-primary h-0.5 md:h-1 lg:h-2 w-full" />
    </div>
  );
};
