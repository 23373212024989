import { FC } from "react";
import { NeofetchColorBlock } from "./NeofetchColorBlock";

export const NeofetchColors: FC = () => {
  return (
    <main>
      {/* Dark Mode */}
      <section className="flex-col hidden dark:flex">
        <div className="flex">
          <NeofetchColorBlock color="#43465A" />
          <NeofetchColorBlock color="#C2294F" />
          <NeofetchColorBlock color="#A6E3A1" />
          <NeofetchColorBlock color="#F9E2AF" />
          <NeofetchColorBlock color="#87B0F8" />
          <NeofetchColorBlock color="#F5C2E7" />
          <NeofetchColorBlock color="#94E2D5" />
          <NeofetchColorBlock color="#CDD6F4" />
        </div>
        <div className="flex">
          <NeofetchColorBlock color="#43465A" />
          <NeofetchColorBlock color="#F38BA8" />
          <NeofetchColorBlock color="#A6E3A1" />
          <NeofetchColorBlock color="#F9E2AF" />
          <NeofetchColorBlock color="#87B0F8" />
          <NeofetchColorBlock color="#F5C2E7" />
          <NeofetchColorBlock color="#94E2D5" />
          <NeofetchColorBlock color="#A1A8C9" />
        </div>
      </section>

      {/* Light Mode */}
      <section className="flex-col flex dark:hidden">
        <div className="flex">
          <NeofetchColorBlock color="#4D4F69" />
          <NeofetchColorBlock color="#D20F39" />
          <NeofetchColorBlock color="#40A02B" />
          <NeofetchColorBlock color="#DF8E1D" />
          <NeofetchColorBlock color="#1E66F5" />
          <NeofetchColorBlock color="#EA76CB" />
          <NeofetchColorBlock color="#179299" />
          <NeofetchColorBlock color="#ACB0BE" />
        </div>
        <div className="flex">
          <NeofetchColorBlock color="#6C6F85" />
          <NeofetchColorBlock color="#D20F39" />
          <NeofetchColorBlock color="#40A02B" />
          <NeofetchColorBlock color="#DF8E1D" />
          <NeofetchColorBlock color="#1E66F5" />
          <NeofetchColorBlock color="#EA76CB" />
          <NeofetchColorBlock color="#179299" />
          <NeofetchColorBlock color="#ACB0BE" />
        </div>
      </section>
    </main>
  );
};
