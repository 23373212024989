import { FC, ReactElement, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PageWrapper } from "../components/PageWrapper";
import { BsGithub } from "react-icons/bs";
import { TfiNewWindow } from "react-icons/tfi";
import { PageHeader } from "../components/PageHeader";

import ValorizeMatch from "../images/projects/valorize/match.png";
import ValorizeInventory from "../images/projects/valorize/inventory.png";
import ValorizeSkinPreview from "../images/projects/valorize/skin-preview.png";
import ValorizeStore from "../images/projects/valorize/store.png";

import CSInvite from "../images/projects/cs-invites/invite.png";
import CSDiscord from "../images/projects/cs-invites/discord.png";

export const ProjectPageWrapper: FC = () => {
  const { project } = useParams();

  const [projectToRender, setProjectToRender] = useState<{
    title: string;
    header: ReactElement;
    description: ReactElement;
    images: string[];
    links: {
      github?: string;
      website?: string;
    };
  }>({
    title: "",
    header: <></>,
    description: <></>,
    images: [],
    links: {},
  });

  useEffect(() => {
    switch (project) {
      case "valorize":
        setProjectToRender({
          title: "VALORIZE",
          header: (
            <>
              VALORIZE is a VALORANT companion app using internal API data,
              allowing players to view hidden data such as MMR/Ranks, match
              history, inventories and more of both themselves and other players
            </>
          ),
          description: (
            <>
              VALORIZE features two main modes:
              <br />
              <br />
              <b>In-game</b>, allowing players to view the ranks, statistics and
              inventories of themselves and players in their games, as well as
              their own item shop. This also enables Rich Presence for Discord,
              showing your rank, selected character, lobby status and more.
              <br />
              <br />
              <b>Offline</b>, showing the inventory and item shop of any
              externally logged-in account without having to launch the game,
              working well for checking the item shops of multiple accounts
              quickly and easily.
              <br />
              <br />
              This project is currently in development and is not yet available
              to the public, though willing testers are welcome to contact me to
              try the development build and provide feedback.
              <br />
              <br />
              The app is natively supported on Windows and Linux, with live data
              only being available on Windows due to VALORANT only supporting
              it.
              <br />
              <br />
              The app is built with TypeScript, Electron, React, Tailwind CSS
              and Zustand.
              <br />
              <br />
              <Link to="/contact" className="text-primary underline">
                Contact me to become a tester
              </Link>
            </>
          ),
          images: [
            ValorizeMatch,
            ValorizeInventory,
            ValorizeSkinPreview,
            ValorizeStore,
          ],
          links: {},
        });
        break;

      case "cs-invites":
        setProjectToRender({
          title: "CS Invite Bot",
          header: (
            <>
              A CS2 (and CS:GO) lobby invitation spoofer tool and exploit,
              allowing sending notifications to any player in the game, even if
              they are not on your friends list, with any custom message
            </>
          ),
          description: (
            <>
              Utilizing protobuf packet manipulation, this tool sends customized
              data to Counter-Strike's internal Game Coordinator (API), allowing
              the invite notifications to show spoofed data. This is mainly used
              to change the skill group (rank) and the country code of the
              invite, where utilizing line breaks in the country code would
              cause invites to be completely broken and unacceptable.
              <br />
              <br />I have previously used my discovery of this exploit to send
              notifications to almost every single CS:GO player linking to a
              Discord server, proving how serious the exploit could be if used
              maliciously. A further technical write-up can be found on the
              GitHub page.
              <br />
              <br />
              The exploit even managed to get an article written about it that
              can be{" "}
              <a
                href="https://exrode.com/counter-strike/csgo-players-get-invitations-from-random-people-en-masse-how-to-defend-yourself-against-it-and-why-is-it-happening"
                target="_blank"
                rel="noreferrer"
                className="text-primary underline"
              >
                read here
              </a>
              , as well as covered in a video that can be{" "}
              <a
                href="https://www.youtube.com/watch?v=YmfAb0K1lzo"
                target="_blank"
                rel="noreferrer"
                className="text-primary underline"
              >
                watched here
              </a>
              .
              <br />
              <br />
              This version of the tool features a Discord bot that can be used
              to send invites to specific players, as well as a few other
              features.
              <br />
              <br />
              As of 2024, the exploit has (mostly) been patched by Valve and is
              therefore less useful, hence why I decided to release the source
              code and a write-up, which both can be found on the GitHub page.
              <br />
              <br />
              The tool is built with TypeScript and discord.js.
            </>
          ),
          images: [CSInvite, CSDiscord],
          links: {
            github: "https://github.com/Tsukani/CS-Invite-Bot",
          },
        });
        break;
      default:
        setProjectToRender({
          title: "404",
          header: <>Project not found</>,
          description: <>Please return to the projects page and try again.</>,
          images: [],
          links: {},
        });
        break;
    }
  }, [project]);

  const [selectedImage, setSelectedImage] = useState(0);

  const handleImageChange = (direction: "left" | "right") => {
    if (direction === "left") {
      setSelectedImage((prev) =>
        prev === 0 ? projectToRender.images.length - 1 : prev - 1
      );
    } else {
      setSelectedImage((prev) =>
        prev === projectToRender.images.length - 1 ? 0 : prev + 1
      );
    }
  };

  return (
    <PageWrapper>
      <div className="flex flex-col gap-16 max-w-screen-2xl">
        <section className="flex justify-center">
          <PageHeader>
            {projectToRender.title.toUpperCase().replaceAll(" ", "//")}
          </PageHeader>
        </section>
        <section className="flex flex-col items-center gap-8 mb-8">
          <div className="w-full flex justify-center items-center relative group bg-black">
            <div
              className={`absolute h-full w-full z-20 flex justify-between text-white ${
                projectToRender.images.length <= 1 && "hidden"
              }`}
            >
              <button
                className="h-full w-16 text-4xl bg-[rgba(215,54,57,0.5)] duration-300 opacity-25 hover:opacity-100"
                onClick={() => handleImageChange("left")}
              >
                &lt;
              </button>
              <button
                className="h-full w-16 text-4xl bg-[rgba(215,54,57,0.5)] duration-300 opacity-25 hover:opacity-100"
                onClick={() => handleImageChange("right")}
              >
                &gt;
              </button>
            </div>
            <div className="w-full relative z-10 group">
              {projectToRender.images.map((image, index) => {
                return (
                  <img
                    key={index}
                    src={image}
                    alt={projectToRender.title}
                    className={`w-full h-full object-cover duration-500 max-h-[15rem] lg:max-h-[30rem] group-hover:lg:max-h-[50rem] ${
                      index === 0 ? "relative" : "absolute top-0 left-0"
                    }`}
                    style={{
                      opacity: selectedImage === index ? 100 : 0,
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="max-w-screen-xl flex flex-col gap-4 bg-[rgba(0,0,0,0.05)] dark:bg-[rgba(255,255,255,0.05)]">
            <div className="bg-gradient-to-r from-primaryDark to-primary h-1 w-full" />
            <div className="p-4 flex flex-col gap-8 px-4 sm:px-8 max-w-screen-2xl">
              <h3 className="text-center whitespace-pre-line">
                {projectToRender.header}
              </h3>
              <h5 className="whitespace-pre-line">
                {projectToRender.description}
              </h5>
              {Object.keys(projectToRender.links).length > 0 && (
                <h3 className="text-center">Links</h3>
              )}
              <div className="flex gap-8 justify-center">
                {projectToRender.links.github && (
                  <a
                    href={projectToRender.links.github}
                    target="_blank"
                    rel="noreferrer"
                    title="GitHub Repository"
                  >
                    <BsGithub className="text-5xl duration-300 hover:text-primary" />
                  </a>
                )}
                {projectToRender.links.website && (
                  <a
                    href={projectToRender.links.website}
                    target="_blank"
                    rel="noreferrer"
                    title="Website"
                  >
                    <TfiNewWindow className="text-5xl duration-300 hover:text-primary" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </PageWrapper>
  );
};
