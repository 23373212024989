import { create } from "zustand";
import { NeofetchData } from "../types/NeofetchData";

type NeofetchDataStore = {
  data: NeofetchData;
  timeSinceLastUpdate: number;

  setData: (data: NeofetchData) => void;
  setTimeSinceLastUpdate: (date: Date) => void;
};

const calculateTimeSinceUpdate = (date: Date) => {
  return Math.ceil(
    Math.abs(new Date().getTime() - new Date(date).getTime()) / (1000 * 60)
  );
};

export const useNeofetchDataStore = create<NeofetchDataStore>()((set) => ({
  data: {
    created_at: undefined,
    OS: "",
    Host: "",
    Kernel: "",
    Uptime: "",
    Packages: "",
    Shell: "",
    WM: "",
    Terminal: "",
    CPU: "",
    GPU: "",
    Memory: "",
    Swap: "",
  },
  timeSinceLastUpdate: -1,

  setData: (data) => set(() => ({ data })),
  setTimeSinceLastUpdate: (date) => {
    set(() => ({ timeSinceLastUpdate: calculateTimeSinceUpdate(date) }));
  },
}));
