import { create } from "zustand";
import { persist } from "zustand/middleware";

type SiteTheme = {
  userSelectedTheme: "auto" | "light" | "dark";
  correctedSiteTheme: "light" | "dark";
  setUserSelectedTheme: (theme: "auto" | "light" | "dark") => void;
  setCorrectedSiteTheme: (theme: "light" | "dark") => void;
};

export const useSiteThemeStore = create<SiteTheme>()(
  persist(
    (set) => ({
      userSelectedTheme: "auto",
      correctedSiteTheme: "light",
      setUserSelectedTheme: (theme) =>
        set(() => ({ userSelectedTheme: theme })),
      setCorrectedSiteTheme: (theme) =>
        set(() => ({ correctedSiteTheme: theme })),
    }),
    {
      name: "site-theme",
    }
  )
);
