import { FC, useState } from "react";
import { PageWrapper } from "../components/PageWrapper";
import supabase from "../apis/supabase";
import { Input } from "../components/Input";
import { Textarea } from "../components/Textarea";
import { Button } from "../components/Button";
import { Socials } from "../components/Socials";
import { PageHeader } from "../components/PageHeader";

export const Contact: FC = () => {
  const [contactData, setContactData] = useState({
    name: "",
    method: "",
    message: "",
  });

  const sendMessage = async () => {
    if (!contactData.name || !contactData.method || !contactData.message)
      return alert("Please fill out all fields!");

    const { data } = await supabase.functions.invoke("contact", {
      body: {
        name: contactData.name,
        method: contactData.method,
        message: contactData.message,
      },
    });

    if (data.success) alert("Message sent!");
  };

  return (
    <PageWrapper>
      <PageHeader>CONTACT//ME</PageHeader>
      <div className="mt-16 px-4 flex flex-col gap-8 max-w-screen-2xl items-center mb-8">
        <h5>
          Feel free to contact me for any reason. Inquries, commissions, and
          random chit-chat are all welcome!
        </h5>
        <Socials />

        <div className="w-full h-0.5 bg-gradient-to-r from-primaryDark to-primary flex justify-center items-center">
          <div className="bg-light dark:bg-black w-max px-4 duration-300">
            OR
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col sm:flex-row gap-4">
            <Input
              label="Name"
              placeholder="Your name"
              onChange={(event) =>
                setContactData((prev) => ({
                  ...prev,
                  name: event.target.value,
                }))
              }
            />
            <Input
              label="Contact Method"
              placeholder="Your email address/Discord/other"
              onChange={(event) =>
                setContactData((prev) => ({
                  ...prev,
                  method: event.target.value,
                }))
              }
            />
          </div>
          <Textarea
            label="Message"
            placeholder="Your message"
            onChange={(event) =>
              setContactData((prev) => ({
                ...prev,
                message: event.target.value,
              }))
            }
          />
          <Button onClick={sendMessage}>Send Message</Button>
        </div>
      </div>
    </PageWrapper>
  );
};
