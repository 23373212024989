import { FC, useEffect } from "react";
import { NeofetchColors } from "./NeofetchColors";
import { NeofetchData } from "../../types/NeofetchData";
import supabase from "../../apis/supabase";
import { useNeofetchDataStore } from "../../stores/neofetchDataStore";

export const Neofetch: FC = () => {
  const neofetchData = useNeofetchDataStore((state) => state.data);
  const setNeofetchData = useNeofetchDataStore((state) => state.setData);
  const timeSinceLastUpdate = useNeofetchDataStore(
    (state) => state.timeSinceLastUpdate
  );

  const getNeofetchData = async () => {
    const { data } = await supabase
      .from("neofetch")
      .select(
        "created_at, OS, Host, Kernel, Uptime, Packages, Shell, WM, Terminal, CPU, GPU, Memory, Swap"
      )
      .order("id", { ascending: false })
      .limit(1);
    if (!data) return;
    const neofetchData = data[0] as NeofetchData;
    setNeofetchData(neofetchData);
  };

  useEffect(() => {
    getNeofetchData();
    const interval = setInterval(() => getNeofetchData(), 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <main
      className="flex flex-col dark:font-bold leading-3 sm:leading-4"
      style={{
        fontFamily: "monospace",
      }}
    >
      <p className="text-[#414254] dark:text-[#43455B] duration-300">
        # My PC Specs // Last update{" "}
        {neofetchData && neofetchData.created_at
          ? new Date(neofetchData.created_at)
              .toLocaleString("en-GB", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace(",", " -")
          : "n/a"}{" "}
        {timeSinceLastUpdate > 10 && (
          <span className="text-primary">(OFFLINE)</span>
        )}
      </p>
      <div className="flex">
        <span
          className="text-[#55B40D] whitespace-pre"
          style={{
            fontFamily: "CaskaydiaCove Nerd Font Mono",
          }}
        >
          &#10095;{" "}
        </span>
        <span className="text-[#4B993C] dark:text-[#8FC08D] duration-300">
          fastfetch
        </span>
      </div>
      <div className="flex">
        {/* prettier-ignore */}
        <section className="hidden xs:flex sm:flex flex-col whitespace-pre text-primary">
          <span>                   -`                   </span>
          <span>                  .o+`                  </span>
          <span>                 `ooo/                  </span>
          <span>                `+oooo:                 </span>
          <span>               `+oooooo:                </span>
          <span>               -+oooooo+:               </span>
          <span>             `/:-:++oooo+:              </span>
          <span>            `/++++/+++++++:             </span>
          <span>           `/++++++++++++++:            </span>
          <span>          `/+++ooooooooooooo/`          </span>
          <span>         ./ooosssso++osssssso+`         </span>
          <span>        .oossssso-````/ossssss+`        </span>
          <span>       -osssssso.      :ssssssso.       </span>
          <span>      :osssssss/        osssso+++.      </span>
          <span>     /ossssssss/        +ssssooo/-      </span>
          <span>   `/ossssso+/:-        -:/+osssso+-    </span>
          <span>  `+sso+:-`                 `.-/+oso:   </span>
          <span> `++:.                           `-/+/  </span>
          <span> .`                                 `/  </span>
        </section>
        <section className="flex flex-col">
          <div className="w-max">
            <div>
              <span className="text-primary">chris</span>@
              <span className="text-primary">tsukani.dev</span>
            </div>
            <div className="h-[14px] flex items-center">
              <div className="bg-black dark:bg-white h-px w-full duration-300" />
            </div>
          </div>
          {neofetchData &&
            Object.entries(neofetchData).map(([key, value]) => (
              <>
                <div key={key}>
                  {key !== "created_at" && (
                    <>
                      <span className="text-primary">{key}</span>:{" "}
                      {value?.toString()}
                    </>
                  )}
                </div>
                {key === "Terminal" && <div className="h-4" />}
              </>
            ))}
          <div className="mt-4 flex flex-col">
            <NeofetchColors />
          </div>
        </section>
      </div>
    </main>
  );
};
