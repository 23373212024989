import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";

export const Footer: FC = () => {
  const [hoveredLinkIndex, setHoveredLinkIndex] = useState<number | null>(null);

  return (
    <footer
      className={`mt-auto w-full text-white p-4 gap-4 flex justify-center bg-primary blackSelection`}
    >
      <main className="max-w-screen-2xl w-full overflow-hidden flex justify-between flex-col gap-12 pb-4 sm:flex-row">
        <section>
          <Link to="/">
            <h2
              style={{
                fontFamily: "Helvetica-Neue-LT-Std-83-Heavy-Extended",
              }}
            >
              TSUKANI//DEV
            </h2>
          </Link>
          <div className="flex flex-col mt-4">
            {[
              { name: "ABOUT//ME", path: "/about" },
              { name: "MY//PROJECTS", path: "/projects" },
              { name: "TECH//CHOICES", path: "/tech" },
              { name: "CONTACT//ME", path: "/contact" },
            ].map(({ name, path }, index) => {
              return (
                <Link
                  to={path}
                  key={path}
                  className={`w-max -translate-x-3 hover:translate-x-0 duration-200 ${
                    hoveredLinkIndex === null || hoveredLinkIndex === index
                      ? "opacity-100"
                      : "opacity-50"
                  }`}
                  onMouseEnter={() => setHoveredLinkIndex(index)}
                  onMouseLeave={() => setHoveredLinkIndex(null)}
                >
                  <h5>&gt; {name}</h5>
                </Link>
              );
            })}
          </div>
        </section>
        <section className="flex items-end">
          <div className="flex items-center gap-1">
            {new Date().getFullYear()}&#47;&#47;Made with <FaHeart />
          </div>
        </section>
      </main>
    </footer>
  );
};
