import { FC } from "react";

export const Input: FC<{
  label: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, placeholder = "", onChange }) => {
  return (
    <div className="flex flex-col w-full">
      <label className="text-xl">{label}</label>
      <input
        onChange={onChange}
        className="border-2 bg-[rgba(0,0,0,0.05)] dark:bg-[rgba(255,255,255,0.05)] border-primary p-2"
        placeholder={placeholder}
      />
    </div>
  );
};
