import { FC, ReactElement, cloneElement } from "react";

export const Icon: FC<{
  icon: ReactElement;
  red?: boolean;
  white?: boolean;
  black?: boolean;
  className?: string;
}> = ({ icon, red, white, black, className }) => {
  return (
    <div className={`flex justify-center items-center h-full ${className}`}>
      {cloneElement(icon, {
        className: `h-full ${
          red
            ? "svgicon-red"
            : white
            ? "svgicon-white"
            : black
            ? "svgicon-black"
            : "svgicon"
        }`,
      })}
    </div>
  );
};
