import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageWrapper } from "../components/PageWrapper";
import { PageHeader } from "../components/PageHeader";
import { FaDizzy } from "react-icons/fa";
import Tudou from "../images/tudou.gif";

export const _404: FC = () => {
  // HEY! Don't spoil the secret... 😡
  const [iconPressed, setIconPressed] = useState(false);
  const [pressCount, setPressCount] = useState(0);
  const [secretFound, setSecretFound] = useState(false);

  useEffect(() => {
    if (pressCount === 10) setSecretFound(true);
  }, [pressCount]);

  return (
    <PageWrapper>
      <PageHeader>NOT//FOUND</PageHeader>
      <section className="my-16">
        <div className="flex flex-col gap-8 items-center px-4 text-center">
          <h2>
            Congratulations! You found a secret page with, well,
            <br />
            {secretFound ? (
              <>
                <span className="font-bold text-primary">
                  okay, something on it
                </span>
                !
              </>
            ) : (
              <>
                <span className="italic text-primary">nothing on it</span>...
              </>
            )}
          </h2>

          <div
            onMouseDown={() => {
              setIconPressed(true);
              setPressCount((prev) => prev + 1);
            }}
            onMouseUp={() => setIconPressed(false)}
            onMouseLeave={() => setIconPressed(false)}
            className={`duration-100 transition-transform ${
              iconPressed && "scale-95"
            } duraiton-300 transition-colors`}
          >
            {secretFound ? (
              <img
                src={Tudou}
                alt=""
                draggable={false}
                className="
                    h-[12rem] sm:h-[16rem] lg:h-[20rem]
                    w-[12rem] sm:w-[16rem] lg:w-[20rem]
                  "
              />
            ) : (
              <FaDizzy className="text-[12rem] sm:text-[16rem] lg:text-[20rem]" />
            )}
          </div>

          <div className="flex flex-col items-center">
            <h3>Let's get back to the main page, shall we?</h3>
            <Link to="/">
              <h5 className="text-primary underline">Home</h5>
            </Link>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};
