import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeToggle } from "./ThemeToggle";
import { ReactComponent as TsukaniDevLogoBlack } from "../images/logo-black.svg";
import { ReactComponent as TsukaniDevLogoWhite } from "../images/logo-white.svg";
import { useSiteThemeStore } from "../stores/siteTheme";
import { GiHamburgerMenu } from "react-icons/gi";
import { usePageScrollStore } from "../stores/pageScroll";

export const Navbar: FC = () => {
  const [rawScrollY, setRawrawScrollY] = useState(0);
  const [forceNavbarSolid, setForceNavbarSolid] = useState(false);
  const [mobileBurgerMenuOpen, setMobileBurgerMenuOpen] = useState(false);

  const handleScroll = () => {
    setRawrawScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const siteTheme = useSiteThemeStore((state) => state.correctedSiteTheme);

  const scrollYProgress = usePageScrollStore((state) => state.scrollYProgress);

  const links = [
    {
      text: "ABOUT",
      link: "/about",
    },
    {
      text: "PROJECTS",
      link: "/projects",
    },
    {
      text: "TECH",
      link: "/tech",
    },
    {
      text: "CONTACT",
      link: "/contact",
    },
  ];

  return (
    <>
      {/* Desktop */}
      <nav
        className={`hidden sm:flex justify-center w-full h-16 px-4 z-[100] fixed duration-300 ${
          rawScrollY > 20 && "bg-primaryDark text-white shadow-md"
        }`}
      >
        <div className="max-w-screen-2xl w-full flex justify-between">
          <div className="flex items-center py-2 overflow-hidden">
            <Link to="/">
              <div className={`duration-300 ${rawScrollY > 20 && "-ml-10"}`}>
                {siteTheme === "light" ? (
                  <TsukaniDevLogoBlack className="w-10 h-10" />
                ) : (
                  <TsukaniDevLogoWhite className="w-10 h-10" />
                )}
              </div>
            </Link>
            <Link
              to="/"
              className={rawScrollY <= 20 ? "pointer-events-none" : ""}
            >
              <h1
                className={`text-2xl md:text-4xl text-white duration-300 ${
                  rawScrollY <= 20 && "opacity-0"
                }`}
              >
                TSUKANI//DEV
              </h1>
            </Link>
          </div>
          <div className={`flex items-center gap-4 font-bold`}>
            {links.map((entry) => {
              return (
                <Link
                  to={entry.link}
                  key={entry.text}
                  className={`duration-300 ${
                    rawScrollY > 20 ? "hover:text-black" : "hover:text-primary"
                  }`}
                >
                  {entry.text}
                </Link>
              );
            })}
            <div
              className={`duration-300 ${
                rawScrollY > 20 ? "hover:text-black" : "hover:text-primary"
              }`}
            >
              <ThemeToggle />
            </div>
          </div>
        </div>

        <div
          className="absolute left-0 top-0 h-full bg-primary -z-10 transition-opacity duration-300"
          style={{
            width: `${scrollYProgress * 100}%`,
            opacity: rawScrollY > 20 ? 1 : 0,
          }}
        />
      </nav>

      {/* Mobile */}
      <nav
        className={`flex sm:hidden justify-center w-full h-16 z-[100] fixed ${
          (rawScrollY > 20 || forceNavbarSolid) &&
          "bg-primary text-white shadow-md"
        }`}
      >
        <div
          className={`max-w-screen-2xl w-full flex justify-between px-4 ${
            (rawScrollY > 20 || forceNavbarSolid) && "bg-primary"
          }`}
        >
          <div className="flex items-center py-2 overflow-hidden">
            <Link to="/" title="TSUKANI//DEV">
              <div
                className={`duration-300 ${
                  (rawScrollY > 20 || forceNavbarSolid) && "-ml-10"
                }`}
              >
                {siteTheme === "light" ? (
                  <TsukaniDevLogoBlack className="w-10 h-10" />
                ) : (
                  <TsukaniDevLogoWhite className="w-10 h-10" />
                )}
              </div>
            </Link>
            <Link
              to="/"
              title="TSUKANI//DEV"
              className={
                rawScrollY <= 20 && !forceNavbarSolid
                  ? "pointer-events-none"
                  : ""
              }
            >
              <h1
                className={`text-2xl text-white duration-300 ${
                  rawScrollY <= 20 && !forceNavbarSolid && "opacity-0"
                }`}
              >
                TSUKANI//DEV
              </h1>
            </Link>
          </div>
          <div className="flex items-center duration-300">
            <GiHamburgerMenu
              className="text-2xl cursor-pointer"
              onClick={() => {
                setMobileBurgerMenuOpen(!mobileBurgerMenuOpen);
                setForceNavbarSolid(!mobileBurgerMenuOpen);
              }}
            />
          </div>

          <div
            className={`w-full top-[3.9rem] fixed left-0 overflow-hidden ${
              !mobileBurgerMenuOpen && "pointer-events-none"
            }`}
          >
            <section
              className={`w-full text-white bg-gradient-to-b from-primary to-primaryDark border-t -mt-px border-white relative p-4 flex justify-between text-2xl duration-300 ${
                mobileBurgerMenuOpen ? "top-0" : "-top-40"
              }`}
            >
              <div className="flex flex-col">
                {links.map((entry) => {
                  return (
                    <Link to={entry.link} key={entry.text}>
                      {entry.text}
                    </Link>
                  );
                })}
              </div>
              <div>
                <ThemeToggle />
              </div>
            </section>
          </div>
        </div>

        {/* Mobile Background Dim */}
        <section
          className={`w-screen h-screen bg-black fixed -z-10 duration-300 ${
            mobileBurgerMenuOpen
              ? "opacity-75"
              : "opacity-0 pointer-events-none"
          }`}
          onClick={() => {
            setMobileBurgerMenuOpen(false);
            setForceNavbarSolid(false);
          }}
        />
      </nav>
    </>
  );
};
