import { FC } from "react";
import { BsGithub, BsLinkedin, BsDiscord } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { PlatformButton } from "./PlatformButton";

export const Socials: FC = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 z-10">
      {[
        {
          name: "GitHub",
          url: "https://github.com/tsukani",
          icon: <BsGithub />,
        },
        {
          name: "LinkedIn",
          url: "https://www.linkedin.com/in/christian-n-jakobsen",
          icon: <BsLinkedin />,
        },
        {
          name: "Discord",
          icon: <BsDiscord />,
        },
        {
          name: "Email",
          url: "mailto:hello@tsukani.dev",
          icon: <FiMail />,
        },
      ].map((item) => {
        return (
          <a
            href={item.name !== "Discord" ? item.url : undefined}
            onClick={() => item.name === "Discord" && alert("Discord: tsukani")}
            target={item.name !== "Discord" ? "_blank" : ""}
            rel="noreferrer"
            key={item.name}
            title={item.name}
            className="cursor-pointer group relative duration-300"
          >
            <PlatformButton>{item.icon}</PlatformButton>
          </a>
        );
      })}
    </div>
  );
};
