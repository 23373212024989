import { FC } from "react";
import { useSiteThemeStore } from "../stores/siteTheme";
import { BsCircleHalf, BsFillMoonFill, BsFillSunFill } from "react-icons/bs";

export const ThemeToggle: FC = () => {
  const userSelectedTheme = useSiteThemeStore(
    (state) => state.userSelectedTheme
  );
  const setUserSelectedTheme = useSiteThemeStore(
    (state) => state.setUserSelectedTheme
  );
  return (
    <div
      onClick={() => {
        const themes = ["auto", "light", "dark"];
        const themeIndex = themes.indexOf(userSelectedTheme);
        const newIndex = themeIndex !== themes.length - 1 ? themeIndex + 1 : 0;
        setUserSelectedTheme(themes[newIndex] as "auto" | "light" | "dark");
      }}
      title={`Change Theme (${userSelectedTheme})`}
      className="text-xl cursor-pointer"
    >
      {userSelectedTheme === "auto" ? (
        <BsCircleHalf />
      ) : userSelectedTheme === "light" ? (
        <BsFillSunFill />
      ) : (
        <BsFillMoonFill />
      )}
    </div>
  );
};
