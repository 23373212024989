import { FC, useEffect, useState } from "react";
import { useSiteThemeStore } from "../stores/siteTheme";
import { motion } from "framer-motion";
import { MenuEntry } from "../components/MenuEntry";
import { ThemeToggle } from "../components/ThemeToggle";
import AnalogClock from "analog-clock-react";

export const Home: FC = () => {
  const siteTheme = useSiteThemeStore((state) => state.correctedSiteTheme);

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  const [selectedTimeFormat, setSelectedTimeFormat] = useState<
    "en-GB" | "en-US"
  >("en-GB");

  return (
    <main className="flex h-full w-full overflow-hidden">
      <section
        className={`h-full border-r-2 border-black dark:border-white hidden md:block w-44 relative`}
      >
        <motion.div
          className="h-full w-full flex flex-col gap-[42rem] absolute"
          animate={{ marginTop: ["-50rem", "0rem"] }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear",
          }}
          style={{
            fontFamily: "Helvetica-Neue-LT-Std-83-Heavy-Extended",
          }}
        >
          {"TSUKANI "
            .repeat(10)
            .split(" ")
            .map((word, index) => {
              return (
                <p
                  key={index}
                  className="text-9xl -rotate-90 text-transparent duration-300 tracking-wider hover:text-black dark:hover:text-white cursor-default"
                  style={{
                    WebkitTextStroke:
                      siteTheme === "light" ? "2px black" : "2px white",
                  }}
                >
                  {word}
                </p>
              );
            })}
        </motion.div>
      </section>
      <section className="flex flex-col py-4 px-8 gap-4">
        {[
          {
            text: "ABOUT ME",
            link: "about",
          },
          {
            text: "MY PROJECTS",
            link: "projects",
          },
          {
            text: "TECH STACK",
            link: "tech",
          },
          {
            text: "CONTACT ME",
            link: "contact",
          },
        ].map((entry, index) => {
          return (
            <MenuEntry key={entry.text} link={entry.link} listIndex={index}>
              {entry.text}
            </MenuEntry>
          );
        })}
      </section>
      <section className="h-full fixed right-0 flex flex-col justify-between items-end p-4 cursor-default">
        <ThemeToggle />
        <section
          className="flex w-32 flex-col gap-2 items-center justify-center"
          onMouseOver={() => setSelectedTimeFormat("en-US")}
          onMouseLeave={() => setSelectedTimeFormat("en-GB")}
        >
          <AnalogClock
            {...{
              useCustomTime: false,
              width: "75px",
              border: true,
              borderColor: siteTheme === "light" ? "black" : "#EAEAEA",
              baseColor: siteTheme === "light" ? "#EAEAEA" : "black",
              centerColor: "transparent",
              centerBorderColor: "transparent",
              handColors: {
                second: "transparent",
                minute: siteTheme === "light" ? "black" : "#EAEAEA",
                hour: "#C93747",
              },
            }}
          />
          <div className="text-xl text-center inline-block">
            {time.toLocaleTimeString(selectedTimeFormat, {
              timeZone: "Europe/Copenhagen",
            })}
            <p className="text-sm">Copenhagen</p>
          </div>
        </section>
      </section>
    </main>
  );
};
