import { FC, cloneElement } from "react";
import { PageWrapper } from "../components/PageWrapper";
import {
  FaCss3Alt,
  FaDigitalOcean,
  FaDocker,
  FaFigma,
  FaGithub,
  FaHtml5,
  FaJava,
  FaNodeJs,
  FaPython,
  FaQuoteLeft,
  FaReact,
  FaUbuntu,
} from "react-icons/fa";
import {
  SiAdobeaftereffects,
  SiAdobeillustrator,
  SiAdobephotoshop,
  SiAdobepremierepro,
  SiAlpinelinux,
  SiApachetomcat,
  SiCsharp,
  SiDeno,
  SiElectron,
  SiExpress,
  SiIonic,
  SiJavascript,
  SiJquery,
  SiKalilinux,
  SiLua,
  SiMongodb,
  SiMysql,
  SiNestjs,
  SiNextdotjs,
  SiPostgresql,
  SiPuppeteer,
  SiSqlite,
  SiSupabase,
  SiSvelte,
  SiTailwindcss,
  SiTypescript,
  SiVercel,
  SiWindows,
} from "react-icons/si";
import { PiBracketsCurly } from "react-icons/pi";
import { GrArchlinux } from "react-icons/gr";
import { TechChoice, TechStackList } from "../types/TechStackList";
import { PageHeader } from "../components/PageHeader";
import Ferris from "../images/ferris.svg";
import FerrisHovered from "../images/ferris-hovered.svg";

export const Tech: FC = () => {
  return (
    <PageWrapper>
      <PageHeader>TECH//CHOICES</PageHeader>
      <div className="mt-16 px-4 flex flex-col gap-8 max-w-screen-2xl items-center">
        <section className="flex flex-col gap-2 max-w-screen-sm bg-[rgba(0,0,0,0.05)] dark:bg-[rgba(255,255,255,0.05)]">
          <div className="bg-gradient-to-r from-primaryDark to-primary h-1 w-full" />
          <div className="p-4">
            <div className="flex flex-col sm:flex-row">
              <FaQuoteLeft className="-mt-2 text-primary text-xl" />
              <h5>
                Any application that can be written in JavaScript, will
                eventually be written in JavaScript.
              </h5>
            </div>
            <p className="w-full text-right">— Jeff Atwood</p>
          </div>
        </section>
        <section className="w-full flex flex-col items-center gap-8 mb-4">
          <h4 className="max-w-screen-lg px-2">
            Below are a few of the technologies I feel sufficient working with.
            I have an affinity for working with TypeScript and React, but I am
            always looking to expand my skillsets by improving and learning new
            technologies.
          </h4>
          <div className="flex flex-wrap justify-center gap-8">
            {(
              [
                {
                  section: "Frontend",
                  tech: {
                    preferences: [
                      {
                        icon: <SiTypescript />,
                        name: "TypeScript",
                        description:
                          "My go-to programming language. Most of my projects are made with TypeScript.",
                      },

                      {
                        icon: <FaReact />,
                        name: "React",
                        description:
                          "My preferred library for building user interfaces.",
                      },
                      {
                        icon: <SiTailwindcss />,
                        name: "Tailwind CSS",
                        description:
                          "A utility-first CSS framework that still stays close to its vanilla CSS roots.",
                      },
                    ],
                    knowledge: [
                      {
                        icon: <SiJavascript />,
                        name: "JavaScript",
                        description:
                          "I have used JavaScript for many years, but I generally prefer TypeScript nowadays for its type safety and improved tooling.",
                      },
                      {
                        icon: <SiNextdotjs />,
                        name: "Next.js",
                        description:
                          "React with extra features and performance. Currently learning it for use in my personal projects.",
                      },
                      {
                        icon: <SiSvelte />,
                        name: "Svelte",
                        description:
                          "Front-end framework that offers fast performance and small bundle sizes.",
                      },

                      {
                        icon: <FaHtml5 />,
                        name: "HTML5",
                        description:
                          "Back to the basics, HTML is where it all started for me.",
                      },
                      {
                        icon: <FaCss3Alt />,
                        name: "CSS3",
                        description:
                          "I generally prefer Tailwind CSS for its utility-first approach and ease of making dynamic responsible interfaces.",
                      },
                      {
                        icon: <SiJquery />,
                        name: "jQuery",
                        description:
                          "Always used to use jQuery until vanilla JS started introducing many of the features it was good for.",
                      },
                    ],
                  },
                },
                {
                  section: "Backend",
                  tech: {
                    preferences: [
                      {
                        icon: <SiTypescript />,
                        name: "TypeScript",
                        description:
                          "My go-to programming language. Most of my projects are made with TypeScript.",
                      },
                      {
                        icon: <FaNodeJs />,
                        name: "Node.js",
                        description:
                          "Backend runtime environment for building terminal/server-side application.",
                      },
                      {
                        icon: <PiBracketsCurly />,
                        name: "TypeORM",
                        description:
                          "Object Relational Mapping library for TypeScript and JavaScript making it easy to work with databases.",
                      },
                      {
                        icon: <SiSupabase />,
                        name: "Supabase",
                        description:
                          "My favorite BaaS solution, Supabase is an open-source alternative to Firebase, built on top of PostgreSQL.",
                      },
                    ],
                    knowledge: [
                      {
                        icon: <SiJavascript />,
                        name: "JavaScript",
                        description:
                          "I have used JavaScript for many years, but I generally prefer TypeScript nowadays for its type safety and improved tooling.",
                      },
                      {
                        icon: <SiDeno />,
                        name: "Deno",
                        description:
                          "Modern and faster alternative to Node.js, but generally I prefer Node.js for its maturity and ecosystem. Mainly used by me to develop Supabase edge functions.",
                      },
                      {
                        icon: <SiNestjs />,
                        name: "Nest.js",
                        description:
                          "A robust framework for building server-side applications.",
                      },
                      {
                        icon: <SiExpress />,
                        name: "Express.js",
                        description:
                          "For smaller projects and APIs, Express.js is an easy and simple tool for making server-side applications.",
                      },
                      {
                        icon: <FaJava />,
                        name: "Java",
                        description:
                          "I have experience with Java for backend development using JPA for persistence, but I generally prefer TypeScript.",
                      },
                    ],
                  },
                },
                {
                  section: "Databases",
                  tech: {
                    preferences: [
                      {
                        icon: <SiSupabase />,
                        name: "Supabase",
                        description:
                          "My favorite BaaS solution, Supabase is an open-source alternative to Firebase, built on top of PostgreSQL.",
                      },
                      {
                        icon: <SiPostgresql />,
                        name: "PostgreSQL",
                        description:
                          "My preferred relational database management system for larger projects.",
                      },
                      {
                        icon: <SiSqlite />,
                        name: "SQLite",
                        description:
                          "Simple and lightweight relational database, mainly used for local app databases.",
                      },
                    ],
                    knowledge: [
                      {
                        icon: <SiMysql />,
                        name: "MySQL",
                        description:
                          "I have experience with MySQL, but I generally prefer PostgreSQL.",
                      },
                      {
                        icon: <SiMongodb />,
                        name: "MongoDB",
                        description:
                          "I have worked a bit with MongoDB, but I need more experience with it to feel comfortable using it for my projects.",
                      },
                    ],
                  },
                },
                {
                  section: "Development Tools",
                  tech: [
                    {
                      icon: <FaGithub />,
                      name: "GitHub",
                      description:
                        "Version control and CI/CD pipelines with GitHub Actions.",
                    },
                    {
                      icon: <FaDocker />,
                      name: "Docker",
                      description:
                        "Containerization of applications for development and deployment.",
                    },

                    {
                      icon: <SiVercel />,
                      name: "Vercel",
                      description:
                        "Easy to use and powerful hosting and deployment platform.",
                    },
                    {
                      icon: <FaDigitalOcean />,
                      name: "DigitalOcean",
                      description: "Simple yet powerful VPS hosting.",
                    },
                  ],
                },
                {
                  section: "Operating Systems",
                  tech: [
                    {
                      icon: <GrArchlinux />,
                      name: "Arch Linux",
                      description:
                        "My preferred OS for development and personal use, allowing me to fully customize my workflow.",
                    },
                    {
                      icon: <SiWindows />,
                      name: "Windows",
                      description:
                        "Generally only used for software and games that doesn't work on Linux.",
                    },
                  ],
                },
                {
                  section: "Other",
                  tech: [
                    {
                      icon: <SiElectron />,
                      name: "Electron",
                      description:
                        "Allows building desktop applications using already known web technologies.",
                    },
                    {
                      icon: <SiPuppeteer />,
                      name: "Puppeteer",
                      description:
                        "Headless browser automation for testing and scraping.",
                    },
                    {
                      icon: <FaReact />,
                      name: "React Native (+ Expo)",
                      description:
                        "Allows building native mobile applications using syntax very similar to React.",
                    },
                    {
                      icon: <SiApachetomcat />,
                      name: "Apache Tomcat",
                      description:
                        "Hosting Java back-end applications made easy.",
                    },
                    {
                      icon: <SiIonic />,
                      name: "Ionic (+ Capacitor)",
                      description:
                        "Allows building native mobile applications using standard React.",
                    },
                    {
                      icon: (
                        <>
                          <div
                            className="group/icon realtive"
                            title={`Rust
Systems programming language that is blazingly fast. 🦀`}
                          >
                            <img
                              src={Ferris}
                              alt="Rust Logo"
                              className="h-12 duration-300 group-hover/icon:opacity-0"
                            />
                            <img
                              src={FerrisHovered}
                              alt="Rust Logo"
                              className="h-12 duration-300 opacity-0 group-hover/icon:opacity-100 absolute -mt-12 z-10"
                            />
                          </div>
                        </>
                      ),
                      name: "Rust",
                      description:
                        "Systems programming language that is blazingly fast. 🦀",
                    },
                    {
                      icon: <FaPython />,
                      name: "Python",
                      description:
                        "I have experience with Python, mainly from small scripts and machine learning projects.",
                    },
                    {
                      icon: <SiLua />,
                      name: "Lua",
                      description:
                        "I have worked with Lua as a plugin/extention language for other apps/games.",
                    },
                    {
                      icon: <SiCsharp />,
                      name: "C#",
                      description:
                        "I have experience with C#, mainly from small learning projects and from Unity game development.",
                    },
                    {
                      icon: <FaFigma />,
                      name: "Figma",
                      description:
                        "Design and prototyping tool used for UI/UX design.",
                    },
                    {
                      icon: <SiAdobephotoshop />,
                      name: "Adobe Photoshop",
                      description:
                        "My go-to tool for image editing and design.",
                    },
                    {
                      icon: <SiAdobepremierepro />,
                      name: "Adobe Premiere Pro",
                      description: "Powerful video editing software.",
                    },
                    {
                      icon: <SiAdobeaftereffects />,
                      name: "Adobe After Effects",
                      description:
                        "Extremely versatile motion graphics and visual effects software.",
                    },
                    {
                      icon: <SiAdobeillustrator />,
                      name: "Adobe Illustrator",
                      description:
                        "Vector graphics editor used for all my logos.",
                    },
                  ],
                },
              ] as TechStackList
            ).map((section) => (
              <div
                key={section.section}
                className="flex w-96 flex-col gap-4 bg-[rgba(0,0,0,0.05)] dark:bg-[rgba(255,255,255,0.05)] duration-300 relative top-0 hover:-top-2 group/container"
              >
                <div className="bg-gradient-to-r from-primaryDark to-primary h-1 w-full" />
                <div className="p-4 flex flex-col gap-8">
                  <h2 className="text-center duration-300 group-hover/container:text-primary">
                    {section.section}
                  </h2>

                  {"preferences" && "knowledge" in section.tech ? (
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col items-center gap-2">
                        <h4>Preferences</h4>
                        <div className="flex flex-wrap justify-center gap-4">
                          {section.tech.preferences.map((tech) =>
                            cloneElement(tech.icon, {
                              className: `text-5xl duration-300 hover:text-primary`,
                              key: tech.name,
                              title: `${tech.name}${
                                tech.description ? `\n${tech.description}` : ""
                              }`,
                            })
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col items-center gap-2">
                        <h4>Knowledge</h4>
                        <div className="flex flex-wrap justify-center gap-4">
                          {section.tech.knowledge.map((tech) =>
                            cloneElement(tech.icon, {
                              className:
                                "text-5xl duration-300 hover:text-primary cursor-default",
                              key: tech.name,
                              title: `${tech.name}${
                                tech.description ? `\n${tech.description}` : ""
                              }`,
                            })
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-wrap justify-center gap-4">
                      {(section.tech as TechChoice[]).map((tech) =>
                        cloneElement(tech.icon, {
                          className: "text-5xl duration-300 hover:text-primary",
                          key: tech.name,
                          title: `${tech.name}${
                            tech.description ? `\n${tech.description}` : ""
                          }`,
                        })
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <h4>And more!</h4>
          <p className="-mt-4">(and always willing to learn)</p>
        </section>
      </div>
    </PageWrapper>
  );
};
