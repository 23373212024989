import { FC } from 'react';

export const NeofetchColorBlock: FC<{ color: string }> = ({ color = '#C2294F' }) => {
  return (
    <div
      className="whitespace-pre"
      style={{
        backgroundColor: color
      }}>
      {'   '}
    </div>
  );
};
