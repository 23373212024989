import { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useSiteThemeStore } from "../stores/siteTheme";
import { motion, useIsPresent } from "framer-motion";

export const Layout: FC = () => {
  const isPresent = useIsPresent();

  const userSelectedTheme = useSiteThemeStore(
    (state) => state.userSelectedTheme
  );
  const siteTheme = useSiteThemeStore((state) => state.correctedSiteTheme);
  const setSiteTheme = useSiteThemeStore(
    (state) => state.setCorrectedSiteTheme
  );

  const getCurrentMachineTheme = window.matchMedia(
    "(prefers-color-scheme: dark)"
  );

  getCurrentMachineTheme.addEventListener(
    "change",
    (event: MediaQueryListEvent) => {
      if (userSelectedTheme === "auto")
        setSiteTheme(event.matches ? "dark" : "light");
      else setSiteTheme(userSelectedTheme);
    }
  );

  useEffect(() => {
    if (userSelectedTheme === "auto")
      setSiteTheme(getCurrentMachineTheme.matches ? "dark" : "light");
    else setSiteTheme(userSelectedTheme);
  }, [userSelectedTheme]);

  return (
    <main className={siteTheme}>
      {/* Main content */}
      <div className="absolute w-full h-full bg-light dark:bg-black text-black dark:text-white duration-300">
        <Outlet />
      </div>

      {/* Transition */}
      <div
        className="fixed w-full h-full flex flex-col pointer-events-none z-[100]"
        id="transition"
      >
        {/* {[...Array(12)].map((_, index) => ( */}
        <motion.div
          // key={index}
          className="flex-1 bg-primary z-40"
          initial={{ scaleY: 1 }}
          animate={{
            scaleY: 0,
            transition: {
              duration: 0.5,
              // delay: index * 0.05 + 0.1,
              ease: [0.75, 0.25, 0.25, 0.75],
            },
          }}
          exit={{
            scaleY: 1,
            transition: {
              // delay: index * 0.05,
              duration: 0.5,
              ease: [0.5, 0, 0.5, 1],
            },
          }}
          style={{
            originY: isPresent ? 0 : 1,
          }}
        />
        {/* ))} */}
      </div>
    </main>
  );
};
