import { FC } from "react";
import { GrArchlinux } from "react-icons/gr";

export const ArchLinuxLogo: FC = () => {
  return (
    <div className="inline-block mx-2 group">
      <div className="flex gap-1">
        <GrArchlinux className="text-primary group-hover:text-[#1C8BC5] duration-150 inline" />{" "}
        <span>
          <b>arch</b>
          <span className="text-primary group-hover:text-[#1C8BC5] duration-150">
            linux
          </span>
        </span>
      </div>
    </div>
  );
};
