import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import stripes from "../images/stripes.svg";
import grid from "../images/grid.png";

import { ReactComponent as Globe } from "../images/astral/VECTOR_29.svg";
import { ReactComponent as OrbitingStar } from "../images/astral/VECTOR_02.svg";
import { ReactComponent as Stars } from "../images/astral/VECTOR_12.svg";
import { ReactComponent as CircledStar } from "../images/astral/VECTOR_23.svg";
import { ReactComponent as ShootingStar } from "../images/astral/VECTOR_20.svg";

import { YearProgressBar } from "../components/YearProgressBar";
import { Icon } from "../components/Icon";
import { useSiteThemeStore } from "../stores/siteTheme";
import { Neofetch } from "../components/neofetch/Neofetch";

import { ArchLinuxLogo } from "../components/ArchLinuxLogo";
import { useNeofetchDataStore } from "../stores/neofetchDataStore";
import { PageWrapper } from "../components/PageWrapper";

import wave1Light from "../images/waves/wave1-light.svg";
import wave1Dark from "../images/waves/wave1-dark.svg";
import wave2Light from "../images/waves/wave2-light.svg";
import wave2Dark from "../images/waves/wave2-dark.svg";
import wave3Light from "../images/waves/wave3-light.svg";
import wave3Dark from "../images/waves/wave3-dark.svg";
import HyprlandLogo from "../images/hyprland-logo.svg";
import { motion, useInView } from "framer-motion";
import { usePageScrollStore } from "../stores/pageScroll";
import supabase from "../apis/supabase";
import { Button } from "../components/Button";
import { Socials } from "../components/Socials";
import { PageHeader } from "../components/PageHeader";
import Portrait from "../images/portrait.png";

export const About: FC = () => {
  const siteTheme = useSiteThemeStore((state) => state.correctedSiteTheme);
  const neofetchData = useNeofetchDataStore((state) => state.data);
  const timeSinceLastUpdate = useNeofetchDataStore(
    (state) => state.timeSinceLastUpdate
  );
  const setTimeSinceLastUpdate = useNeofetchDataStore(
    (state) => state.setTimeSinceLastUpdate
  );

  // Birthday/Age
  const today = new Date();
  const birthday = new Date("September 19, 2001 00:00:00");
  const nextBirthday = new Date(
    today.getFullYear(),
    birthday.getMonth(),
    birthday.getDate()
  );

  const isBirthday =
    nextBirthday.getDate() === today.getDate() &&
    nextBirthday.getMonth() === today.getMonth();

  const age =
    today >= nextBirthday
      ? today.getFullYear() - birthday.getFullYear()
      : today.getFullYear() - birthday.getFullYear() - 1;

  const daysUntilBirthday = isBirthday
    ? 0
    : nextBirthday < today
    ? Math.ceil(
        (nextBirthday.setFullYear(today.getFullYear() + 1) - today.getTime()) /
          (1000 * 60 * 60 * 24)
      )
    : Math.ceil(
        (nextBirthday.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
      );

  const [osuRanks, setOsuRanks] = useState({
    global: 0,
    country: 0,
  });

  useEffect(() => {
    (async () => {
      const { data } = await supabase.functions.invoke("osu-rank");

      if (data[0]) {
        setOsuRanks({
          global: data[0].pp_rank,
          country: data[0].pp_country_rank,
        });
      }
    })();
  }, []);

  const [terminalHovered, setTerminalHovered] = useState(false);

  const getStatusMessage = () => {
    return neofetchData?.created_at
      ? timeSinceLastUpdate >= 10
        ? "OFFLINE"
        : `Last update: ${timeSinceLastUpdate} minute${
            timeSinceLastUpdate !== 1 ? "s" : ""
          } ago`
      : "Unknown";
  };

  useEffect(() => {
    if (!neofetchData?.created_at) return;
    setTimeSinceLastUpdate(new Date(neofetchData.created_at));
  }, [neofetchData]);

  const section1Ref = useRef<HTMLDivElement>(null);
  const sectionInView1 = useInView(section1Ref, {
    margin:
      window.innerWidth <= 600
        ? "1000px 0px -150px 0px"
        : "1000px 0px -250px 0px",
  });

  const section2Ref = useRef<HTMLDivElement>(null);
  const sectionInView2 = useInView(section2Ref, {
    margin:
      window.innerWidth <= 600
        ? "1000px 0px -100px 0px"
        : "1000px 0px -250px 0px",
  });

  const section3Ref = useRef<HTMLDivElement>(null);
  const sectionInView3 = useInView(section3Ref, {
    margin:
      window.innerWidth <= 600
        ? "1000px 0px -150px 0px"
        : "1000px 0px -250px 0px",
  });

  const scrollY = usePageScrollStore((state) => state.scrollY);

  return (
    <PageWrapper>
      <PageHeader>ABOUT//ME</PageHeader>
      <section className="mt-16 w-[51rem] h-[22rem] sm:h-[32rem] md:h-auto flex flex-col items-center p-4 scale-50 -translate-y-1/4 sm:scale-75 sm:-translate-y-[12.5%] md:scale-100 md:-translate-y-0">
        <h3 className="font-bold w-full flex justify-between">
          {"TSUKANI//DEV".split("").map((letter, index) => {
            return <span key={index}>{letter}</span>;
          })}
        </h3>
        <div className="flex justify-center w-full">
          <section className="w-1/4 p-1 flex flex-col gap-2">
            <div className="border-2 border-black dark:border-white w-full aspect-square p-2 duration-300">
              <Icon icon={<Globe />} />
            </div>
            <div className="text-center">
              <YearProgressBar />
            </div>
            <div
              className="border-2 border-black h-96 dark:invert duration-300"
              style={{
                backgroundImage: `url(${grid})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            />
            <div
              className="border-2 border-black w-full h-16 dark:invert duration-300"
              style={{
                backgroundImage: `url(${stripes})`,
                backgroundSize: "110%",
                backgroundPosition: "center",
              }}
            />
          </section>
          <section className="w-3/4 p-1 flex flex-col gap-2">
            <div className="border-2 border-black dark:border-white w-full h-full overflow-hidden flex justify-center items-center relative duration-300">
              <div
                className="absolute left-4 top-4 z-50 border-2 border-primary flex font-bold cursor-help"
                title={getStatusMessage() || "OFFLINE"}
              >
                <div className="bg-primary text-white dark:text-black px-2 flex duration-300">
                  STATUS:
                </div>
                <div className="px-2 bg-light dark:bg-black duration-300">
                  {neofetchData?.created_at && timeSinceLastUpdate <= 10 ? (
                    <span className="text-green-500">• ONLINE</span>
                  ) : (
                    <span className="text-primary">• OFFLINE</span>
                  )}
                </div>
              </div>
              <div className="absolute z-20 bottom-4 right-4 flex flex-col items-end">
                <h3 className="bg-black text-white dark:bg-light dark:text-black w-max px-4 py-1 border-b-4 border-primary duration-300">
                  Heya, I'm
                </h3>
                <h1 className="text-8xl bg-gradient-to-r to-primary from-primaryDark inline-block text-transparent bg-clip-text">
                  CHRIS
                </h1>
              </div>
              <div className="z-10 h-full w-full">
                <img
                  src={Portrait}
                  alt=""
                  className="w-full absolute grayscale select-none"
                  draggable={false}
                />
              </div>
              <div
                className="absolute"
                style={{
                  top: `-${scrollY / 15}%`,
                }}
              >
                {new Array(20).fill("").map((_, index) => {
                  return (
                    <h1
                      key={index}
                      className="text-transparent text-[7.5rem] leading-[6rem] tracking-wider duration-300"
                      style={{
                        WebkitTextStroke:
                          siteTheme === "light" ? "2px black" : "2px white",
                      }}
                    >
                      TSUKANI
                    </h1>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
        <div className="flex items-center p-2 bord<er-b-2 border-black dark:border-white w-full">
          <div className="h-16 w-16">
            <Icon icon={<Stars />} red />
          </div>
          <div>
            A{" "}
            <span
              className="underline cursor-help"
              title={
                isBirthday
                  ? "It's my birthday today!"
                  : `${daysUntilBirthday} day${
                      daysUntilBirthday !== 1 ? "s" : ""
                    } until next birthday`
              }
            >{`${age}-year-old`}</span>{" "}
            Copenhagen-based full-stack web developer with a passion for unique
            design and a love for learning new things. Specializing in React,
            TypeScript, and Node.js
            <div className="inline-block absolute ml-2 w-12">
              <Icon icon={<OrbitingStar />} />
            </div>
            <div className="inline-block absolute ml-2 w-12">
              <Icon icon={<OrbitingStar />} />
            </div>
          </div>
        </div>
      </section>

      <section className="w-full flex flex-col pt-32 items-center">
        <div className="bg-gradient-to-r from-primaryDark to-primary h-1 w-full max-w-screen-2xl" />

        <div className="flex flex-col items-center relative w-full">
          <motion.div
            ref={section1Ref}
            className="max-w-screen-2xl flex flex-col md:flex-row items-center md:justify-between pt-8 duration-500"
            style={{
              opacity: sectionInView1 ? 1 : 0,
              y: sectionInView1 ? 0 : 100,
            }}
          >
            <h4 className="md:w-3/4 p-4 text-center md:text-start">
              For the last {age - 15} years, I've enjoyed making all sorts of
              web applications, both big and small — anything from simple
              automation tools and bots to fully-fledged web applications. I'm
              passionate about creative design and experiment with it in various
              ways. In addition to web development, I have experience with image
              and video editing using tools such as Photoshop, After Effects,
              and Premiere Pro.
            </h4>
            <div className="aspect-square w-32 md:w-52">
              <Icon icon={<ShootingStar />} red />
            </div>
          </motion.div>
          <div className="relative -bottom-1 h-[300px]">
            <img
              src={wave1Light}
              className="w-screen h-full dark:hidden"
              alt=""
            />
            <img
              src={wave1Dark}
              className="w-screen h-full hidden dark:inline-block"
              alt=""
            />
          </div>
        </div>

        <div className="flex flex-col items-center bg-primary w-full blackSelection">
          <motion.div
            ref={section2Ref}
            className="max-w-screen-2xl flex flex-col md:flex-row items-center md:justify-between py-4 md:py-16 xl:py-32 duration-500"
            style={{
              opacity: sectionInView2 ? 1 : 0,
              y: sectionInView2 ? 0 : 100,
            }}
          >
            <div className="aspect-square w-32 md:w-52">
              <Icon icon={<CircledStar />} white />
            </div>
            <h4 className="md:w-3/4 p-4 text-center md:text-end text-white">
              I have always had a passion for teaching and sharing my
              experiences with those interested around me. I have previously
              volunteered as a tutor for teens interested in programming and web
              development. Outside of development, I enjoy playing and competing
              in video games with{" "}
              <a
                href="https://osu.ppy.sh/users/5146144"
                target="_blank"
                rel="noreferrer"
                className="underline font-bold"
              >
                osu!
              </a>{" "}
              currently being my primary game. In osu! I am currently ranked{" "}
              <a
                href={`https://osu.ppy.sh/rankings/taiko/performance?page=${Math.ceil(
                  osuRanks.global / 50
                )}#scores`}
                target="_blank"
                rel="noreferrer"
                className="underline font-bold"
                title="Peak rank: #286"
              >
                #{osuRanks.global}
              </a>{" "}
              on the global leaderboard and{" "}
              <a
                href="https://osu.ppy.sh/rankings/taiko/performance?country=DK"
                target="_blank"
                rel="noreferrer"
                className="underline font-bold"
              >
                #{osuRanks.country}
              </a>{" "}
              on the Danish leaderboard. I have also previously been ranked{" "}
              <span className="font-bold">Radiant</span> (~top 300) in VALORANT.
            </h4>
          </motion.div>
        </div>

        <div className="flex flex-col items-center relative w-full bg-black dark:bg-light duration-300 pb-16 xl:pb-32">
          <div className="relative -top-1 h-[300px]">
            <img
              src={wave2Light}
              className="w-screen h-full dark:hidden"
              alt=""
            />
            <img
              src={wave2Dark}
              className="w-screen h-full hidden dark:inline-block"
              alt=""
            />
          </div>
          <div className="max-w-screen-2xl flex flex-col text-center justify-between text-white dark:text-black">
            <motion.div
              className="duration-500"
              ref={section3Ref}
              style={{
                opacity: sectionInView3 ? 1 : 0,
                y: sectionInView3 ? 0 : 100,
              }}
            >
              <h4 className="p-4">
                I have always loved being creative and crafting new things and
                experiences. For years I have been able to express this love
                with custom unique websites, but I also exercise this passion by
                making {/* <Link to="/osu" className="underline font-bold"> */}
                custom levels for games such as osu!
                {/* </Link> */}, handcrafting real-life figurines as well as
                designing and animating graphical content.
              </h4>

              <h4 className="p-4">
                Being a designer and tinkerer at heart has always heavily
                influenced the way I use my devices. To satisfy the need for
                both being creative and efficient, I have adopted Linux as my
                primary operating system, with{" "}
                <a
                  title="(arch btw)"
                  href="https://archlinux.org/"
                  target="_blank"
                  rel="noreferrer"
                  className="relative top-px"
                >
                  <ArchLinuxLogo />
                </a>{" "}
                being the distro that fully allows me to fully customize my PCs
                to my liking.
              </h4>
              <div className="flex justify-center">
                <h5
                  className="cursor-pointer text-primary flex flex-col items-center w-max"
                  onClick={() =>
                    alert(
                      "Currently work in progress, will be released once finished!"
                    )
                  }
                >
                  <div className="flex">
                    View my
                    <div className="flex gap-1 mx-1">
                      <img
                        src={HyprlandLogo}
                        className="h-6 w-6 relative top-px"
                        alt=""
                      />
                      Hyprland
                    </div>{" "}
                    dotfiles
                  </div>
                  <div className="w-full h-px bg-primary -mt-0.5" />
                </h5>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      <section className="relative w-screen duration-300 transition-colors">
        <div className="pt-32 flex flex-col w-full justify-center items-center">
          <div
            onMouseOver={() => setTerminalHovered(true)}
            onMouseLeave={() => setTerminalHovered(false)}
            className="z-10 relative mb-16 text-[8px] sm:text-xs inline-block p-8 mx-4 bg-terminalBgLight dark:bg-terminalBgDark duration-300 opacity-90 rounded-xl border-2 border-primary text-[#4C4F69] dark:text-[#CDD6F4] md:hover:scale-110"
          >
            <Neofetch />
          </div>
          <div
            className={`absolute top-0 left-0 w-full h-full bg-light-mode dark:bg-dark-mode bg-cover bg-center duration-300 ${
              terminalHovered
                ? "brightness-75 md:brightness-[0.35]"
                : "brightness-75"
            }`}
          />
          <div className="relative pb-2 h-[300px]">
            <img
              src={wave3Light}
              className="w-screen h-full dark:hidden"
              alt=""
            />
            <img
              src={wave3Dark}
              className="w-screen h-full hidden dark:inline-block"
              alt=""
            />

            <div className="absolute bottom-0 left-0 w-full h-2 bg-light dark:bg-black"></div>
          </div>
        </div>
      </section>

      <section className="-mt-8 w-full flex flex-col items-center py-12 relative gap-8">
        <div className="text-center flex flex-col gap-8 w-max z-10">
          <h3>Find me here</h3>
          <Socials />

          <div className="w-full h-0.5 bg-gradient-to-r from-primaryDark to-primary flex justify-center items-center">
            <div className="bg-light dark:bg-black w-max px-4 duration-300">
              OR
            </div>
          </div>

          <Link to="/contact">
            <Button>Send me a message </Button>
          </Link>
        </div>
        <div
          className="absolute w-full h-full top-0 dark:invert duration-300"
          style={{
            backgroundImage:
              "radial-gradient(rgba(0, 0, 0, 0.25) 1px, transparent 0)",
            backgroundSize: "40px 40px",
            backgroundPosition: "center",
          }}
        />
      </section>
    </PageWrapper>
  );
};
