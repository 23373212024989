import { FC } from "react";

export const YearProgressBar: FC = () => {
  return (
    <div className="w-full h-full relative">
      <div className="w-full h-full relative z-10">
        {new Date().toLocaleDateString("en-GB", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </div>
      <div
        className="h-full absolute top-0 bg-gradient-to-r from-primaryDark to-primary z-0"
        style={{
          width: `${
            ((new Date().getTime() -
              new Date(new Date().getFullYear(), 0, 1).getTime()) /
              (365 * 24 * 60 * 60 * 1000)) *
            100
          }%`,
        }}
      />
    </div>
  );
};
