import { FC, ReactNode } from "react";

export const PlatformButton: FC<{
  children: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <button className="relative group duration-300 bg-black dark:bg-light z-10 cursor-pointer aspect-square">
      <div className="flex relative items-center justify-center p-4 bg-black dark:bg-light">
        <h2 className="dark:text-white group-hover:text-white duration-300 p-4 z-20 relative">
          {children}
        </h2>
        <div className="overflow-hidden w-full h-full absolute top-0 left-0 flex items-center">
          <div
            className={`w-full aspect-square absolute z-10 -translate-x-full -translate-y-full group-hover:translate-x-0 group-hover:translate-y-0 scale-150 duration-500 ${className}"
            }`}
          >
            <div className="absolute bg-gradient-to-r from-primary to-primaryDark w-full h-full duration-500 rotate-45 right-1 bottom-1 group-hover:right-0 group-hover:bottom-0" />
          </div>
        </div>
        <div className="top-0.5 bottom-0.5 left-0.5 right-0.5 absolute bg-light dark:bg-black" />
      </div>
    </button>
  );
};
