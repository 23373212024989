import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { ScrollerMotion, ScrollerMotionRef } from "scroller-motion";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";
import { motion, useMotionValue } from "framer-motion";
import { usePageScrollStore } from "../stores/pageScroll";

export const PageWrapper: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;

  const setScrollY = usePageScrollStore((state) => state.setScrollY);
  const setScrollPercentage = usePageScrollStore(
    (state) => state.setScrollYProgress
  );

  const scrollerMotion = useRef<ScrollerMotionRef>();
  const scrollY = useMotionValue(0);

  const [scrollDisabled, setScrollDisabled] = useState(true);

  useEffect(() => {
    const scrollPosition = scrollerMotion.current!.scrollY.on("change", (v) => {
      setScrollY?.(v);
    });

    const scrollPercentage = scrollerMotion.current!.scrollYProgress.on(
      "change",
      (v) => {
        setScrollPercentage?.(v);
      }
    );

    return () => {
      scrollPosition();
      scrollPercentage();
    };
  }, [scrollY]);

  useEffect(() => {
    setTimeout(() => {
      setScrollDisabled(false);
    }, 500);
  }, []);

  return (
    <div
      className={`h-full w-full absolute ${
        scrollDisabled ? "overflow-y-hidden" : "yes"
      }`}
    >
      <Navbar />

      <ScrollerMotion
        disabled={isTouchDevice}
        ref={scrollerMotion}
        className="top-0 w-full absolute"
      >
        <motion.div
          initial={{ translateY: "7.5vh", opacity: 0 }}
          animate={{
            translateY: 0,
            opacity: 1,
            transition: { duration: 0.5, ease: [0.75, 0.25, 0.25, 0.75] },
          }}
          exit={{
            translateY: "-7.5vh",
            opacity: 0,
            transition: { duration: 0.5, ease: [0.75, 0.25, 0.25, 0.75] },
          }}
        >
          <div
            className={`flex flex-col min-h-screen w-full bg-light dark:bg-black duration-300 ${
              // Hacky solution to fix scroller-motion offset issue
              !scrollDisabled && "absolute"
            }`}
          >
            <main
              className={`flex flex-col items-center pt-16 flex-grow w-full`}
            >
              {children}
              <Footer />
            </main>
          </div>
        </motion.div>
      </ScrollerMotion>
    </div>
  );
};
