import { FC } from "react";

export const Textarea: FC<{
  label: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}> = ({ label, placeholder = "", onChange }) => {
  return (
    <div className="flex flex-col">
      <label className="text-xl">{label}</label>
      <textarea
        onChange={onChange}
        className="border-2 bg-[rgba(0,0,0,0.05)] dark:bg-[rgba(255,255,255,0.05)] border-primary p-2 min-h-[100px] max-h-[200px]"
        placeholder={placeholder}
      />
    </div>
  );
};
